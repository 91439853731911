import { useCallback, useState, useEffect } from 'react';
import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
const affiliatedCompaniesService = {
  useAffiliatedCompanies(elsterId) {
    const [affiliatedCompanies, setAffiliatedCompanies] = useState(null);
    const fetchAffiliatedCompanies = useErrorHandling(useCallback(async () => {
      const affiliatedCompanies = await affiliatedCompaniesService.all(elsterId);
      setAffiliatedCompanies(affiliatedCompanies);
    }, [elsterId]));
    useEffect(() => {
      fetchAffiliatedCompanies();
    }, [fetchAffiliatedCompanies]);
    return [affiliatedCompanies, setAffiliatedCompanies];
  },
  useSaveAffiliatedCompany(elsterId, setAffiliatedCompanies, setSelectedAffiliatedCompany) {
    return useErrorHandling(useCallback(async (affiliatedCompany, affiliatedCompanies) => {
      if (affiliatedCompany.id) {
        const updatedAffiliatedCompany = await affiliatedCompaniesService.update(elsterId, affiliatedCompany);
        const newAffiliatedCompanies = [...affiliatedCompanies];
        const index = newAffiliatedCompanies.findIndex(ac => ac.id === updatedAffiliatedCompany.id);
        newAffiliatedCompanies[index] = updatedAffiliatedCompany;
        setAffiliatedCompanies(newAffiliatedCompanies);
        setSelectedAffiliatedCompany(null);
      } else {
        const newAffiliatedCompany = await affiliatedCompaniesService.create(elsterId, affiliatedCompany);
        setAffiliatedCompanies([...affiliatedCompanies, newAffiliatedCompany]);
        setSelectedAffiliatedCompany(null);
      }
    }, [elsterId, setAffiliatedCompanies, setSelectedAffiliatedCompany]));
  },
  create(elsterId, affiliated_company) {
    return fetch(`/api/elsters/${elsterId}/affiliated_companies/`, {
      ...request.post,
      body: JSON.stringify({
        affiliated_company
      })
    });
  },
  update(elsterId, affiliated_company) {
    return fetch(`/api/elsters/${elsterId}/affiliated_companies/${affiliated_company.id}`, {
      ...request.put,
      body: JSON.stringify({
        affiliated_company
      })
    });
  },
  all(elsterId) {
    return fetch(`/api/elsters/${elsterId}/affiliated_companies/`, {
      ...request.get
    });
  },
  delete(elsterId, affiliated_company_id) {
    return fetch(`/api/elsters/${elsterId}/affiliated_companies/${affiliated_company_id}`, {
      ...request.delete
    });
  }
};
export default affiliatedCompaniesService;