import { Document, elsterDocxUtils } from './utils/elster_docx_utils';
import { createTimezonedDate } from '@rd-web-markets/shared/dist/util/date/dateUtils';
import { createElements } from './create_elements';
import generateFootersSetting from './settings/generateFootersSetting';
import generateHeadersSetting from './settings/generateHeadersSetting';
import generateDocumentProperties from './settings/generateDocumentProperties';
import { mainTextStyles, smallTextStyles } from './styles/elsterDocStyles';
import { useSelector } from 'react-redux';
export const generateElsterDocument = async _ref => {
  let {
    data,
    user
  } = _ref;
  const locale = user?.local_language || 'en';
  const fileNameDate = createTimezonedDate(new Date()).toCustomLocaleDateString(true);
  const {
    createDocument
  } = elsterDocxUtils;
  const getDocumentName = () => {
    return `${fileNameDate}_${data.company_name.replace(' ', '_').toLowerCase()}_elster_document_${data.claim_group_financial_year}_${user.first_name.toLowerCase()}_${locale}`;
  };

  // Create the document
  const doc = new Document({
    styles: {
      paragraphStyles: [{
        id: 'mainText',
        name: 'mainText',
        basedOn: 'Normal',
        next: 'Normal',
        quickFormat: true,
        run: {
          ...mainTextStyles
        }
      }, {
        id: 'small_text',
        name: 'small_text',
        basedOn: 'Normal',
        next: 'Normal',
        quickFormat: true,
        run: {
          ...smallTextStyles
        }
      }]
    },
    sections: [{
      properties: generateDocumentProperties(),
      headers: generateHeadersSetting(),
      footers: generateFootersSetting(),
      children: createElements(data, user)
    }]
  });
  createDocument(getDocumentName(), doc);
};