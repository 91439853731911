import { buildService } from '@rd-web-markets/shared/dist/services/service';
import { useCallback, useEffect, useState } from 'react';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import omit from 'lodash.omit';
const api = buildService('/project_elster_details/{id}/additional_documentations', {
  only: ['all', 'delete', 'create']
}, {
  only: ['postFormData', 'putFormData']
});
const projectDocumentationService = {
  useFetchUploads: (claimProjectReport, setProjectReports) => {
    const [uploads, setUploads] = useState(null);
    const [loading, setLoading] = useState(false);
    const setProjectAdditionalDocumentations = useCallback(uploads => {
      setProjectReports(prevReports => {
        const index = prevReports.findIndex(r => r.id === claimProjectReport.id);
        prevReports[index].additional_documentations = uploads;
        return prevReports;
      });
    }, [claimProjectReport.id, setProjectReports]);
    const fetchUploads = useErrorHandling(useCallback(async () => {
      const response = await api.all(claimProjectReport.project_elster_detail.id);
      setUploads(response);
      setProjectAdditionalDocumentations(response);
    }, [claimProjectReport.project_elster_detail.id, setProjectAdditionalDocumentations]));
    useEffect(() => {
      fetchUploads();
    }, [fetchUploads]);
    return [loading, fetchUploads, uploads, setLoading];
  },
  useUpload: (fetchUploads, claimProjectReport, handleToaster, setLoading) => {
    const upload = useErrorHandling(useCallback(async (document, file) => {
      if (!file) {
        handleToaster('Attach a file before submitting', 'warning');
      } else {
        setLoading(true);
        let fd = new FormData();
        fd.append('file', file);
        fd.append('additional_documentation', JSON.stringify(document));
        await api.postFormData(claimProjectReport.project_elster_detail.id, fd);
        await fetchUploads();
        setLoading(false);
      }
    }, [setLoading, claimProjectReport.project_elster_detail.id, fetchUploads, handleToaster]));
    return [upload];
  },
  useDelete: (fetchUploads, setLoading, claimProjectReport) => {
    const deleteFile = useErrorHandling(useCallback(async uploadId => {
      if (window.confirm('Are you sure ?')) {
        setLoading(true);
        await api.delete(claimProjectReport.project_elster_detail.id, uploadId);
        fetchUploads();
        setLoading(false);
      }
    }, [setLoading, claimProjectReport.project_elster_detail.id, fetchUploads]));
    return [deleteFile];
  }
};
projectDocumentationService.useCrud = (claimProjectReport, setProjectReports, handleToaster) => {
  const [loading, fetchUploads, uploads, setLoading] = projectDocumentationService.useFetchUploads(claimProjectReport, setProjectReports);
  const [upload] = projectDocumentationService.useUpload(fetchUploads, claimProjectReport, handleToaster, setLoading);
  const [deleteFile] = projectDocumentationService.useDelete(fetchUploads, setLoading, claimProjectReport);
  return {
    uploads,
    upload,
    deleteFile,
    loading
  };
};
export default projectDocumentationService;