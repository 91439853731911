import { buildService } from '@rd-web-markets/shared/dist/services/service';
import { useCallback, useEffect, useState } from 'react';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import omit from 'lodash.omit';
const api = buildService('/project_elster_details/{id}/project_owners', {
  only: ['delete', 'create']
}, {
  only: ['postFormData', 'putFormData']
});
const projectOwnerService = {
  useProjectOwner: rowData => {
    const [body, setBody] = useState({
      ...rowData,
      year: rowData.year || 'after_30_06_2020'
    });
    const [isSaved, setIsSaved] = useState(true);
    const [uploadAllowed, setUploadAllowed] = useState(!!rowData.id); // upload is allowd for existing rows, new rows should update on press of save button

    useEffect(() => {
      rowData.year = rowData.year || 'after_30_06_2020';
      setBody({
        ...rowData
      });
    }, [rowData]);
    return [body, setBody, setUploadAllowed, uploadAllowed, isSaved, setIsSaved];
  },
  useAddData: (setBody, rowData, setSelectedProject) => {
    const addData = useCallback(response => {
      setSelectedProject(selectedProject => {
        const projectOwners = selectedProject.project_elster_detail.project_owners ? selectedProject.project_elster_detail.project_owners : [];
        const projectOwnersUpdated = [...projectOwners, response];
        return {
          ...selectedProject,
          project_elster_detail: {
            ...selectedProject.project_elster_detail,
            project_owners: projectOwnersUpdated
          }
        };
      });
      setBody({
        ...rowData
      });
    }, [setBody, rowData, setSelectedProject]);
    return [addData];
  },
  useUpdateData: (body, setSelectedProject) => {
    const updateData = useCallback((response, documentOnly) => {
      setSelectedProject(selectedProject => {
        let targetProjectOwner = selectedProject.project_elster_detail.project_owners.find(n => n.id === Number(body.id));
        if (documentOnly) {
          targetProjectOwner.document = {
            ...response.document
          };
        } else {
          targetProjectOwner = {
            ...response
          };
        }
        return {
          ...selectedProject
        };
      });
    }, [body.id, setSelectedProject]);
    return [updateData];
  },
  useUploadFile: (rowData, body, updateData) => {
    const uploadFile = useErrorHandling(useCallback(async formData => {
      formData.append('project_owner', JSON.stringify({
        id: rowData.id
      }));
      const response = await api.putFormData(body.project_elster_detail_id, body.id, formData);
      updateData(response, true);
    }, [body.project_elster_detail_id, body.id, rowData.id, updateData]));
    return [uploadFile];
  },
  useSaveData: (rowData, setIsSaved, updateData, addData) => {
    const saveData = useErrorHandling(useCallback(async body => {
      const method = rowData.id ? 'putFormData' : 'postFormData';
      const fieldValues = omit(body, ['document', 'id', 'created_at', 'updated_at', 'project_elster_detail_id']);
      const fd = new FormData();
      fd.append('project_owner', JSON.stringify({
        ...fieldValues
      }));
      let response;
      if (method === 'postFormData') {
        response = await api[method](body.project_elster_detail_id, fd);
      } else {
        response = await api[method](body.project_elster_detail_id, body.id, fd);
      }
      method === 'postFormData' ? addData(response) : updateData(response);
      setIsSaved(true);
    }, [setIsSaved, rowData.id, addData, updateData]));
    return [saveData];
  },
  useCeateData: (setUploadAllowed, body, addData, setIsSaved, emptyRowId, removeEmptyRow) => {
    const createNewData = useErrorHandling(useCallback(async formData => {
      setUploadAllowed(false);
      let fieldValues = omit(body, ['id', 'created_at', 'updated_at', 'project_elster_detail_id']);
      formData.append('project_owner', JSON.stringify(fieldValues));
      const response = await api.postFormData(body.project_elster_detail_id, formData);
      addData(response);
      setIsSaved(true);
      removeEmptyRow(emptyRowId);
    }, [setUploadAllowed, setIsSaved, addData, body, emptyRowId, removeEmptyRow]));
    return [createNewData];
  },
  useDelete: setSelectedProject => {
    const deleteData = useErrorHandling(useCallback(async body => {
      await api.delete(body.project_elster_detail_id, body.id);
      setSelectedProject(selectedProject => {
        return {
          ...selectedProject,
          project_elster_detail: {
            ...selectedProject.project_elster_detail,
            project_owners: selectedProject.project_elster_detail.project_owners.filter(projectOwner => projectOwner.id !== Number(body.id))
          }
        };
      });
    }, [setSelectedProject]));
    return [deleteData];
  }
};
projectOwnerService.useCrud = (rowData, data, setData, removeEmptyRow, emptyRowId, setSelectedProject) => {
  const [body, setBody, setUploadAllowed, uploadAllowed, isSaved, setIsSaved] = projectOwnerService.useProjectOwner(rowData);
  const [addData] = projectOwnerService.useAddData(setBody, rowData, setSelectedProject);
  const [updateData] = projectOwnerService.useUpdateData(body, setSelectedProject);
  const [uploadFile] = projectOwnerService.useUploadFile(rowData, body, updateData);
  const [saveData] = projectOwnerService.useSaveData(rowData, setIsSaved, updateData, addData);
  const [createNewData] = projectOwnerService.useCeateData(setUploadAllowed, body, addData, setIsSaved, emptyRowId, removeEmptyRow);
  const [deleteData] = projectOwnerService.useDelete(setSelectedProject);
  return {
    uploadAllowed,
    setIsSaved,
    body,
    uploadFile,
    saveData,
    createNewData,
    deleteData,
    isSaved,
    setBody,
    setUploadAllowed
  };
};
export default projectOwnerService;