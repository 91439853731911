import { elsterDocxUtils, emptyLine } from '../utils/elster_docx_utils';
import i18n from 'i18next';
import logoImage from '@rd-web-markets/shared/dist/assets/images/logo_vertical_light.png';
import { dataUriToBlob } from '@rd-web-markets/shared/dist/util/helpers/dataUrlToBlob';
const beforeTableOfContent = data => {
  const {
    t
  } = i18n;
  const logo = dataUriToBlob(logoImage);
  const {
    createParagraph,
    createTextRun,
    createImageRun
  } = elsterDocxUtils;
  const headerStyle = {
    font: 'Georgia',
    size: 56,
    color: '00AEEF'
  };
  const imageStyle = {
    width: 76,
    height: 97,
    horizontalPosition: {
      offset: 6014400
    },
    verticalPosition: {
      offset: 714400
    }
  };
  return [createParagraph([createTextRun(t('transmission_protocol'), headerStyle)], {
    spacing: {
      before: 350
    }
  }), createParagraph([createTextRun(`${data.company_name} & ${data.claim_group_financial_year}`), createImageRun(logo, imageStyle)], {
    style: 'mainText',
    spacing: {
      before: 250,
      after: 50
    }
  }), emptyLine, emptyLine, emptyLine];
};
export default beforeTableOfContent;