import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import { buildService } from '@rd-web-markets/shared/dist/services/service';
import { useEffect, useCallback, useState } from 'react';
const api = buildService('/companies/{id}/elsters', {
  only: ['get', 'create']
});
const elsterService = {
  useFetchElster: (companyId, setLoading, elsterId) => {
    const [elster, setElster] = useState(null);
    const fetchElster = useErrorHandling(useCallback(async () => {
      setLoading(true);
      const elster = await api.get(companyId, elsterId);
      setElster(elster);
    }, [companyId, setLoading, elsterId]), useCallback(async () => {
      setLoading(false);
    }, [setLoading]));
    useEffect(() => {
      if (!elsterId) return;
      fetchElster();
    }, [fetchElster, elsterId]);
    return [elster, setElster];
  },
  useCreateElster: (companyId, setLoading) => {
    const createElster = useErrorHandling(useCallback(async elster => {
      setLoading && setLoading(true);
      await api.create(companyId, {
        elster
      });
    }, [companyId, setLoading]), useCallback(async () => {
      setLoading && setLoading(false);
    }, [setLoading]));
    return createElster;
  }
};
elsterService.useCrud = function (companyId) {
  let setLoading = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : () => {};
  let elsterId = arguments.length > 2 ? arguments[2] : undefined;
  const [elster, setElster] = elsterService.useFetchElster(companyId, setLoading, elsterId);
  const createElster = elsterService.useCreateElster(companyId, setLoading);
  return {
    elster,
    setElster,
    createElster
  };
};
export default elsterService;