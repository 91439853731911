import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import { buildService } from '@rd-web-markets/shared/dist/services/service';
import { useCallback, useEffect, useState } from 'react';
const api = buildService('/claim_project_reports/{id}/first_fast_check', {
  only: ['update']
});
const firstFastCheckService = {
  useUpdateFirstFastCheck: (projectId, setLoading) => {
    const updateFirstFastCheck = useErrorHandling(useCallback(async firstFastCheck => {
      setLoading && setLoading(true);
      await api.update(projectId, '', {
        first_fast_check: firstFastCheck
      });
    }, [projectId, setLoading]), useCallback(async () => {
      setLoading && setLoading(false);
    }, [setLoading]));
    return [updateFirstFastCheck];
  }
};
firstFastCheckService.useCrud = (projectId, setLoading) => {
  const [updateFirstFastCheck] = firstFastCheckService.useUpdateFirstFastCheck(projectId, setLoading);
  return {
    updateFirstFastCheck
  };
};
export default firstFastCheckService;