import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
function create(claimId, apportionment) {
  return fetch(`/api/claims/${claimId}/claim_employees/${apportionment.claim_employee_id}/claim_employee_rnd_apportionments`, {
    ...request.post,
    body: JSON.stringify({
      claim_employee_rnd_apportionment: apportionment
    })
  });
}
function update(claimId, apportionment) {
  return fetch(`/api/claims/${claimId}/claim_employees/${apportionment.claim_employee_id}/claim_employee_rnd_apportionments/${apportionment.id}`, {
    ...request.put,
    body: JSON.stringify({
      claim_employee_rnd_apportionment: apportionment
    })
  });
}
const claimEmployeeRnDAppointmentsService = {
  create,
  update
};
export default claimEmployeeRnDAppointmentsService;