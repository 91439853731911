import { api } from '@rd-web-markets/shared/dist/services/service';
const generalService = {
  get: function (id, route, subRoute) {
    let subRouteId = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
    return api.get(`/${route}/${id}/${subRoute}/${subRouteId}`);
  },
  update: function (id, route, subRoute, body) {
    let subRouteId = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : '';
    return api.update(`/${route}/${id}/${subRoute}/${subRouteId}`, body);
  },
  delete: (id, route, subRoute, subRouteId) => api.delete(`/${route}/${id}/${subRoute}/${subRouteId}`),
  create: (id, route, subRoute, body) => api.create(`/${route}/${id}/${subRoute}`, body),
  putFormData: function (id, route, subRoute, body) {
    let subRouteId = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : '';
    return api.putFormData(`/${route}/${id}/${subRoute}/${subRouteId}`, body);
  },
  postFormData: (id, route, subRoute, body) => api.postFormData(`/${route}/${id}/${subRoute}`, body)
};
export default generalService;