import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
const claimEmployeesService = {
  all(claimId, projectId) {
    return fetch(`/api/claims/${claimId}/claim_project_reports/${projectId}/claim_employees`, request.get);
  },
  delete(claimEmployee) {
    return fetch(`/api/claims/${claimEmployee.claim_id}/claim_project_reports/${claimEmployee.claim_project_report_id}/claim_employees/${claimEmployee.id}`, request.delete);
  },
  update(claimEmployee) {
    return fetch(`/api/claims/${claimEmployee.claim_id}/claim_project_reports/${claimEmployee.claim_project_report_id}/claim_employees/${claimEmployee.id}`, {
      ...request.put,
      body: JSON.stringify({
        claim_employee: claimEmployee
      })
    });
  },
  create(claimEmployee) {
    return fetch(`/api/claims/${claimEmployee.claim_id}/claim_project_reports/${claimEmployee.claim_project_report_id}/claim_employees`, {
      ...request.post,
      body: JSON.stringify({
        claim_employee: claimEmployee
      })
    });
  }
};
export default claimEmployeesService;