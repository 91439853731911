import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
const firstFastChecksService = {
  get(reportId) {
    return fetch(`/api/claim_project_reports/${reportId}/first_fast_check`, request.get);
  },
  update(first_fast_check) {
    return fetch(`/api/claim_project_reports/${first_fast_check.claim_project_report_id}/first_fast_check`, {
      ...request.put,
      body: JSON.stringify({
        first_fast_check
      })
    });
  }
};
export default firstFastChecksService;