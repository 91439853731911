import { buildService } from '@rd-web-markets/shared/dist/services/service';
import { useCallback, useEffect, useState } from 'react';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import omit from 'lodash.omit';
const api = buildService('/project_elster_details/{id}/staff_costs', {
  only: ['delete', 'create']
}, {
  only: ['postFormData', 'putFormData']
});
const satfflistService = {
  useStaffList: rowData => {
    const [body, setBody] = useState({
      ...rowData,
      year: rowData.year || 'after_30_06_2020'
    });
    const [isSaved, setIsSaved] = useState(true);
    useEffect(() => {
      rowData.year = rowData.year || 'after_30_06_2020';
      setBody({
        ...rowData
      });
    }, [rowData]);
    return [body, setBody, isSaved, setIsSaved];
  },
  useAddData: (setBody, setData, rowData, selectedProject) => {
    const addData = useCallback((response, data) => {
      selectedProject.project_elster_detail.total_for_staff_cost = {
        ...response.total_calculations
      };
      setData([...data, response]);
      setBody({
        ...rowData
      });
    }, [rowData, selectedProject, setData, setBody]);
    return [addData];
  },
  useUpdateData: (body, selectedProject, setData) => {
    const updateData = useCallback((response, data) => {
      const selectedDataIndex = data.findIndex(n => n.id === Number(body.id));
      data[selectedDataIndex] = {
        ...response
      };
      selectedProject.project_elster_detail.total_for_staff_cost = {
        ...response.total_calculations
      };
      setData([...data]);
    }, [body.id, selectedProject, setData]);
    return [updateData];
  },
  useSaveData: (rowData, setIsSaved, addData, updateData, emptyRowId, removeEmptyRow, data) => {
    const saveData = useErrorHandling(useCallback(async body => {
      const method = rowData.id ? 'putFormData' : 'postFormData';
      const fieldValues = omit(body, ['id', 'created_at', 'updated_at', 'project_elster_detail_id', 'calculations', 'document']);
      const fd = new FormData();
      fd.append('staff_cost', JSON.stringify({
        ...fieldValues
      }));
      let response;
      if (method === 'postFormData') {
        response = await api[method](body.project_elster_detail_id, fd);
        addData(response, data);
        removeEmptyRow(emptyRowId);
      } else {
        response = await api[method](body.project_elster_detail_id, body.id, fd);
        updateData(response, data);
      }
      setIsSaved(true);
    }, [setIsSaved, rowData.id, addData, data, updateData, emptyRowId, removeEmptyRow]));
    return [saveData];
  },
  useDelete: (selectedProject, setData) => {
    const deleteData = useErrorHandling(useCallback(async (body, data) => {
      await api.delete(body.project_elster_detail_id, body.id);
      const deletedNameListIndex = data.findIndex(n => n.id === Number(body.id));
      data.splice(deletedNameListIndex, 1);
      const newGrossWage = selectedProject.project_elster_detail.total_for_staff_cost.gross_wage_total - body.calculations.gross_wage;
      const newSocialSecurityTotal = selectedProject.project_elster_detail.total_for_staff_cost.b_excluding_special_payments_total - body.calculations.b_excluding_special_payments;
      selectedProject.project_elster_detail.total_for_staff_cost = {
        gross_wage_total: newGrossWage,
        b_excluding_special_payments_total: newSocialSecurityTotal
      };
      setData([...data]);
    }, [selectedProject, setData]));
    return [deleteData];
  }
};
satfflistService.useCrud = (rowData, selectedProject, data, setData, removeEmptyRow, emptyRowId) => {
  const [body, setBody, isSaved, setIsSaved] = satfflistService.useStaffList(rowData);
  const [addData] = satfflistService.useAddData(setBody, setData, rowData, selectedProject);
  const [updateData] = satfflistService.useUpdateData(body, selectedProject, setData);
  const [saveData] = satfflistService.useSaveData(rowData, setIsSaved, addData, updateData, emptyRowId, removeEmptyRow, data);
  const [deleteData] = satfflistService.useDelete(selectedProject, setData);
  return {
    body,
    saveData,
    deleteData,
    isSaved,
    setBody,
    setIsSaved
  };
};
export default satfflistService;