import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
const financialYearsService = {
  update(financialDetailId, financial_year) {
    return fetch(`/api/financial_details/${financialDetailId}/financial_years/${financial_year.id}`, {
      ...request.put,
      body: JSON.stringify({
        financial_year
      })
    });
  }
};
export default financialYearsService;