import { buildService } from '@rd-web-markets/shared/dist/services/service';
import { useCallback, useEffect, useState } from 'react';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import omit from 'lodash.omit';
const api = buildService('/project_elster_details/{id}/staff_costs', {
  only: ['delete', 'create']
}, {
  only: ['postFormData', 'putFormData']
});
const nameListsService = {
  useNameList: rowData => {
    const [body, setBody] = useState({
      ...rowData,
      year: rowData.year || 'after_30_06_2020'
    });
    const [isSaved, setIsSaved] = useState(true);
    const [uploadAllowed, setUploadAllowed] = useState(!!rowData.id); // upload is allowd for existing rows, new rows should update on press of save button

    useEffect(() => {
      rowData.year = rowData.year || 'after_30_06_2020';
      setBody({
        ...rowData
      });
    }, [rowData]);
    return [body, setBody, setUploadAllowed, uploadAllowed, isSaved, setIsSaved];
  },
  useAddData: (setBody, setData, rowData, selectedProject) => {
    const addData = useCallback((response, data) => {
      selectedProject.project_elster_detail.total_for_staff_cost = {
        ...response.total_calculations
      };
      setData([...data, response]);
      setBody({
        ...rowData
      });
    }, [rowData, selectedProject, setData, setBody]);
    return [addData];
  },
  useUpdateData: (body, selectedProject, setData) => {
    const updateData = useCallback((response, documentOnly, data) => {
      const selectedDataIndex = data.findIndex(n => n.id === Number(body.id));
      if (documentOnly) {
        data[selectedDataIndex].document = {
          ...response.document
        };
      } else {
        data[selectedDataIndex] = {
          ...response
        };
      }
      selectedProject.project_elster_detail.total_for_staff_cost = {
        ...response.total_calculations
      };
      setData([...data]);
    }, [body.id, selectedProject, setData]);
    return [updateData];
  },
  useUploadFile: (rowData, body, data, updateData) => {
    const uploadFile = useErrorHandling(useCallback(async formData => {
      formData.append('staff_cost', JSON.stringify({
        id: rowData.id
      }));
      const response = await api.putFormData(body.project_elster_detail_id, body.id, formData);
      updateData(response, true, data);
    }, [body, rowData.id, updateData, data]));
    return [uploadFile];
  },
  useSaveData: (data, setIsSaved, updateData) => {
    const saveData = useErrorHandling(useCallback(async body => {
      let fieldValues = omit(body, ['id', 'created_at', 'updated_at', 'project_elster_detail_id']);
      if (body.fulltime) {
        fieldValues = omit(body, ['digital_record', 'rel_agreed_annual_working_hrs']);
      }
      const fd = new FormData();
      fd.append('staff_cost', JSON.stringify({
        ...fieldValues
      }));
      const response = await api.putFormData(body.project_elster_detail_id, body.id, fd);
      updateData(response, false, data);
      setIsSaved(true);
    }, [setIsSaved, updateData, data]));
    return [saveData];
  },
  useCeateData: (setUploadAllowed, body, data, addData, setIsSaved, emptyRowId, removeEmptyRow) => {
    const createNewData = useErrorHandling(useCallback(async formData => {
      setUploadAllowed(false);
      let fieldValues = omit(body, ['id', 'created_at', 'updated_at', 'project_elster_detail_id']);
      if (body.fulltime) {
        fieldValues = omit(body, ['digital_record', 'rel_agreed_annual_working_hrs']);
      }
      formData.append('staff_cost', JSON.stringify(fieldValues));
      const response = await api.postFormData(body.project_elster_detail_id, formData);
      addData(response, data);
      setIsSaved(true);
      removeEmptyRow(emptyRowId);
    }, [setIsSaved, setUploadAllowed, addData, data, body, removeEmptyRow, emptyRowId]));
    return [createNewData];
  },
  useDelete: (selectedProject, setData) => {
    const deleteData = useErrorHandling(useCallback(async (body, data) => {
      await api.delete(body.project_elster_detail_id, body.id);
      const deletedNameListIndex = data.findIndex(n => n.id === Number(body.id));
      const newGrossWage = selectedProject.project_elster_detail.total_for_staff_cost.gross_wage_total - body.calculations.gross_wage;
      const newSocialSecurityTotal = selectedProject.project_elster_detail.total_for_staff_cost.b_excluding_special_payments_total - body.calculations.b_excluding_special_payments;
      selectedProject.project_elster_detail.total_for_staff_cost = {
        gross_wage_total: newGrossWage,
        b_excluding_special_payments_total: newSocialSecurityTotal
      };
      data.splice(deletedNameListIndex, 1);
      setData([...data]);
    }, [selectedProject, setData]));
    return [deleteData];
  }
};
nameListsService.useCrud = (rowData, selectedProject, data, setData, removeEmptyRow, emptyRowId) => {
  const [body, setBody, setUploadAllowed, uploadAllowed, isSaved, setIsSaved] = nameListsService.useNameList(rowData);
  const [addData] = nameListsService.useAddData(setBody, setData, rowData, selectedProject);
  const [updateData] = nameListsService.useUpdateData(body, selectedProject, setData);
  const [uploadFile] = nameListsService.useUploadFile(rowData, body, data, updateData);
  const [saveData] = nameListsService.useSaveData(data, setIsSaved, updateData);
  const [createNewData] = nameListsService.useCeateData(setUploadAllowed, body, data, addData, setIsSaved, emptyRowId, removeEmptyRow);
  const [deleteData] = nameListsService.useDelete(selectedProject, setData);
  return {
    uploadAllowed,
    body,
    uploadFile,
    saveData,
    createNewData,
    deleteData,
    isSaved,
    setBody,
    setUploadAllowed
  };
};
export default nameListsService;