import { BorderStyle, convertInchesToTwip, HeadingLevel, ShadingType } from 'docx';
export const headerColumnWidth = 3505;
export const contentColumnWidth = 6205;
export const projectNameColumnWidth = 8205;
export const logoColumnWidth = 1500;
export const borders = {
  top: {
    style: BorderStyle.SINGLE,
    size: 1,
    color: '666E7E'
  },
  bottom: {
    style: BorderStyle.SINGLE,
    size: 1,
    color: '666E7E'
  },
  left: {
    style: BorderStyle.SINGLE,
    size: 1,
    color: '666E7E'
  },
  right: {
    style: BorderStyle.SINGLE,
    size: 1,
    color: '666E7E'
  }
};
export const bordersInvisible = {
  top: {
    style: BorderStyle.NONE,
    size: 4,
    color: '#FFFFFF'
  },
  bottom: {
    style: BorderStyle.NONE,
    size: 4,
    color: '#FFFFFF'
  },
  left: {
    style: BorderStyle.NONE,
    size: 4,
    color: '#FFFFFF'
  },
  right: {
    style: BorderStyle.NONE,
    size: 4,
    color: '#FFFFFF'
  }
};
export const sideBorders = {
  top: {
    style: BorderStyle.NONE,
    size: 0,
    color: 'FFFFFF'
  },
  bottom: {
    style: BorderStyle.NONE,
    size: 0,
    color: 'FFFFFF'
  },
  left: {
    style: BorderStyle.SINGLE,
    size: 1,
    color: '#5CD2FF'
  },
  right: {
    style: BorderStyle.SINGLE,
    size: 1,
    color: '#5CD2FF'
  }
};

// Basically background color

export const shading = {
  fill: '#5CD2FF',
  type: ShadingType.SOLID,
  color: '#5CD2FF'
};
export const tableMargins = {
  top: convertInchesToTwip(0.01),
  bottom: convertInchesToTwip(0.08),
  right: convertInchesToTwip(0.05),
  left: convertInchesToTwip(0.08)
};
export const mainTextStyles = {
  font: 'Franklin Gothic Book',
  size: 20,
  color: '666E7E'
};
export const smallTextStyles = {
  font: 'Franklin Gothic Book',
  size: 14,
  color: '666E7E'
};
export const italicTextStyles = {
  font: 'Franklin Gothic Book',
  size: 16,
  color: '666E7E',
  italics: true
};
export const grayTitlesMainNotBold = {
  font: 'Franklin Gothic Book',
  size: 24,
  color: '666E7E'
};
export const grayTitlesMain = {
  font: 'Franklin Gothic Book',
  size: 24,
  color: '666E7E',
  bold: true
};
export const grayTitlesSmall = {
  font: 'Franklin Gothic Book',
  size: 20,
  color: '666E7E'
};
export const blueTitlesSmall = {
  font: 'Franklin Gothic Book',
  size: 20,
  color: '00AEEF'
};
export const bookmarkStyle = {
  font: 'Georgia',
  size: 32,
  color: '00AEEF',
  bold: true
};
export const bookmarkStyle2 = {
  font: 'Franklin Gothic Book',
  size: 24,
  color: '00AEEF',
  bold: true
};
export const bookmarkParagraphOptions = {
  heading: HeadingLevel.HEADING_1,
  spacing: {
    before: 500,
    after: 300
  },
  indent: {
    left: 350
  }
};
export const bookmarkParagraphOptions2 = {
  heading: HeadingLevel.HEADING_1,
  spacing: {
    before: 500,
    after: 300
  }
};
export const mainParagraphOptions = {
  spacing: {
    before: 300,
    after: 200
  }
};