import { elsterDocxUtils, emptyLine } from '../utils/elster_docx_utils';
import i18n from 'i18next';
import { IORADO_PROJECTS_EIGHT, IORADO_PROJECTS_FIVE, IORADO_PROJECTS_FOUR, IORADO_PROJECTS_NINE, IORADO_PROJECTS_ONE, IORADO_PROJECTS_SEVEN, IORADO_PROJECTS_SIX, IORADO_PROJECTS_TEN, IORADO_PROJECTS_THREE, IORADO_PROJECTS_TWO } from '../constants/elsterDocxConstants';
import generalTable from '../utils/generalTable';
import { blueTitlesSmall, bookmarkParagraphOptions, bookmarkStyle2, grayTitlesMain } from '../styles/elsterDocStyles';
const projects = data => {
  const {
    t
  } = i18n;
  const {
    createBookmark,
    createTextRunInParagraph
  } = elsterDocxUtils;
  const mainParagraphOptions = {
    spacing: {
      before: 300,
      after: 200
    }
  };
  return [createBookmark(t('tk_iorado_projects_2'), 'anchor_tk_iorado_projects_2', bookmarkStyle2, bookmarkParagraphOptions), createTextRunInParagraph(t('iorado_projects_subtitle'), grayTitlesMain, mainParagraphOptions), ...data.elster_projects_data.flatMap(elster_project => {
    return [createBookmark(`${t('project')}: ${elster_project.project_name}`, 'anchor_iorado_projects_2', blueTitlesSmall, bookmarkParagraphOptions), generalTable(elster_project, IORADO_PROJECTS_ONE), createTextRunInParagraph(t('iorado_only_for_in_house_research_and_development_projects'), grayTitlesMain, mainParagraphOptions), generalTable(elster_project, IORADO_PROJECTS_TWO), createTextRunInParagraph(t('iorado_wages_for_own_employees'), grayTitlesMain, mainParagraphOptions), generalTable(elster_project, IORADO_PROJECTS_THREE), createTextRunInParagraph(t('iorado_own_cont_of_a_sole_proprietor'), grayTitlesMain, mainParagraphOptions), generalTable(elster_project, IORADO_PROJECTS_FOUR), createTextRunInParagraph(t('iorado_own_cont_by_shareholders'), grayTitlesMain, mainParagraphOptions), generalTable(elster_project, IORADO_PROJECTS_FIVE), createTextRunInParagraph(t('iorado_only_for_contract_research'), grayTitlesMain, mainParagraphOptions), generalTable(elster_project, IORADO_PROJECTS_SIX), createTextRunInParagraph(t('iorado_contractors_data'), grayTitlesMain, mainParagraphOptions), generalTable(elster_project, IORADO_PROJECTS_SEVEN), createTextRunInParagraph(t('only_when_received_or_applying_for_other_subsidies'), grayTitlesMain, mainParagraphOptions), generalTable(elster_project, IORADO_PROJECTS_EIGHT), createTextRunInParagraph(t('only_for_corporation_projects'), grayTitlesMain, mainParagraphOptions), generalTable(elster_project, IORADO_PROJECTS_NINE), createTextRunInParagraph(t('determination_of_eligible_expenses_from_wages_title'), grayTitlesMain, mainParagraphOptions), createTextRunInParagraph(t('iorado_determination_b4_2020'), grayTitlesMain, mainParagraphOptions), generalTable(elster_project, IORADO_PROJECTS_TEN), createTextRunInParagraph(t('iorado_determination_after_2020'), grayTitlesMain, mainParagraphOptions), generalTable(elster_project, IORADO_PROJECTS_TEN), emptyLine];
  }), emptyLine];
};
export default projects;