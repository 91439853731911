import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
const groupOverviewNotesService = {
  delete(groupId, noteId) {
    return fetch(`/api/elsters/${groupId}/group_overview_notes/${noteId}`, request.delete);
  },
  create(groupId, group_overview_note) {
    return fetch(`/api/elsters/${groupId}/group_overview_notes`, {
      ...request.post,
      body: JSON.stringify({
        group_overview_note
      })
    });
  },
  edit(groupId, group_overview_note) {
    return fetch(`/api/elsters/${groupId}/group_overview_notes/${group_overview_note.id}`, {
      ...request.put,
      body: JSON.stringify({
        group_overview_note
      })
    });
  }
};
export default groupOverviewNotesService;