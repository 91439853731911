import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import { buildService } from '@rd-web-markets/shared/dist/services/service';
import { useCallback, useEffect, useState } from 'react';
import * as QueryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
const api = buildService('/elsters/{id}/claim_project_reports', {
  only: ['all', 'get']
});
const elsterProjectsService = {
  useFetchAll: _ref => {
    let {
      accountType,
      elsterId,
      initialQueryParams = '',
      setLoading
    } = _ref;
    const history = useHistory();
    const location = useLocation();
    const [projects, setProjects] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [queryParams, setQueryParams] = useState({
      ...QueryString.parse(location.search || initialQueryParams)
    });
    const fetchAllProjetcs = useErrorHandling(useCallback(async queryParams => {
      setLoading && setLoading(true);
      const projects = await api.all(elsterId, queryParams);
      setTotalPages(projects.pages);
      setProjects(projects);
      history.push({
        path: `${accountType}/elsters`,
        search: QueryString.stringify(queryParams)
      });
    }, [history, accountType, elsterId, setLoading]), useCallback(() => {
      setLoading && setLoading(false);
    }, [setLoading]));
    useEffect(() => {
      elsterId && fetchAllProjetcs(queryParams);
    }, [fetchAllProjetcs, queryParams, elsterId]);
    return [projects, setProjects, fetchAllProjetcs, totalPages, setTotalPages, queryParams, setQueryParams];
  },
  useGet: (elsterId, reportId) => {
    const [project, setProject] = useState(null);
    const fetchAndSetReport = useErrorHandling(useCallback(async () => {
      if (!elsterId || !reportId) return;
      const projectResponse = await api.get(elsterId, reportId);
      setProject({
        ...projectResponse
      });
    }, [elsterId, reportId]));
    useEffect(() => {
      fetchAndSetReport();
    }, [fetchAndSetReport]);
    return [project, setProject, fetchAndSetReport];
  }
};
export default elsterProjectsService;