import { pageBreak } from './utils/elster_docx_utils';
import generalCompanyInformation from './sections/generalCompanyInformation';
import beforeTableOfContent from './sections/beforeTableOfContent';
import applicationAllowance from './sections/applicationAllowanceResearch';
import projects from './sections/projects';
import tableOfContent2 from './sections/tableOfContent2';
import section3 from './sections/section3';
import section4 from './sections/section4';
import section5 from './sections/section5';
import section6 from './sections/section6';
import section78 from './sections/section78';
export const createElements = (data, user) => {
  const documentContent = [...beforeTableOfContent(data), ...tableOfContent2(), pageBreak, ...applicationAllowance(data), ...generalCompanyInformation(data), ...projects(data), ...section3(data), ...section4(data), ...section5(data), ...section6(data, user), ...section78(data)];
  return documentContent;
};