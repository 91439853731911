import { elsterDocxUtils, emptyLine } from '../utils/elster_docx_utils';
import i18n from 'i18next';
import { FINAL_DECLARATION_ONE } from '../constants/elsterDocxConstants';
import generalTable from '../utils/generalTable';
import { bookmarkParagraphOptions, bookmarkStyle2, grayTitlesMain } from '../styles/elsterDocStyles';
import { section6TextsEn } from './section6_texts/section6Texts';
import { useSelector } from 'react-redux';
const section6 = (data, user) => {
  const {
    t
  } = i18n;
  const userLanguage = user?.local_language === 'de' ? 'de' : 'en';
  const {
    createBookmark,
    createTextRunInParagraph,
    createParagraph,
    createParagraphWithLineBreaks
  } = elsterDocxUtils;
  const mainParagraphOptions = {
    spacing: {
      before: 300,
      after: 200
    }
  };
  return [createBookmark(t('tk_final_dec_6'), 'anchor_tk_final_dec_6', bookmarkStyle2, bookmarkParagraphOptions), createParagraphWithLineBreaks(section6TextsEn[userLanguage], {
    style: 'small_text'
  }), createTextRunInParagraph(t('tk_final_dec_6_long_text'), grayTitlesMain, mainParagraphOptions), generalTable(data, FINAL_DECLARATION_ONE), emptyLine];
};
export default section6;