import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import generalRouteSubRouteService from '@rd-web-markets/shared/dist/services/general_route_subroute.service';
import React, { useCallback, useEffect, useState } from 'react';
const employeeDetailFileService = {
  useGetFiles(projectElsterDetailId) {
    const [employeeDetailFiles, setEmployeeDetailFiles] = useState([]);
    const getEmployeeDetailFiles = useErrorHandling(useCallback(async () => {
      const response = await this.get(projectElsterDetailId);
      setEmployeeDetailFiles([...response]);
    }, [projectElsterDetailId]));
    const uploadEmployeeFile = useErrorHandling(useCallback(async body => {
      await this.postFormData(projectElsterDetailId, body);
      getEmployeeDetailFiles();
    }, [getEmployeeDetailFiles, projectElsterDetailId]));
    const deleteEmployeeFile = useErrorHandling(useCallback(async fileId => {
      await this.delete(projectElsterDetailId, fileId);
      getEmployeeDetailFiles();
    }, [getEmployeeDetailFiles, projectElsterDetailId]));
    useEffect(() => {
      getEmployeeDetailFiles();
    }, [getEmployeeDetailFiles]);
    return [employeeDetailFiles, uploadEmployeeFile, deleteEmployeeFile];
  },
  get(projectElsterDetailId) {
    return generalRouteSubRouteService.getAll(projectElsterDetailId, 'project_elster_details', 'employee_detail_files');
  },
  delete(projectElsterDetailId, fileId) {
    return generalRouteSubRouteService.delete(projectElsterDetailId, 'project_elster_details', 'employee_detail_files', fileId);
  },
  postFormData(projectElsterDetailId, body) {
    return generalRouteSubRouteService.postFormData(projectElsterDetailId, 'project_elster_details', 'employee_detail_files', body);
  }
};
export default employeeDetailFileService;