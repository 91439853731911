import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
const yearFinancialDetailsService = {
  update(projectContractorId, financial_detail) {
    return fetch(`/api/project_contractors/${projectContractorId}/financial_details/${financial_detail.id}`, {
      ...request.put,
      body: JSON.stringify({
        financial_detail
      })
    });
  },
  upload(yearFinancialDetailId, formData) {
    return fetch(`/api/financial_details/${yearFinancialDetailId}/documents`, {
      ...request.post,
      headers: {
        'Accept': 'application/json, text/plain, */*'
      },
      body: formData
    });
  },
  create(projectContractorId, financial_detail) {
    return fetch(`/api/project_contractors/${projectContractorId}/financial_details`, {
      ...request.post,
      body: JSON.stringify({
        financial_detail
      })
    });
  },
  delete(projectContractorId, financialDetailId) {
    return fetch(`/api/project_contractors/${projectContractorId}/financial_details/${financialDetailId}`, {
      ...request.delete
    });
  }
};
export default yearFinancialDetailsService;