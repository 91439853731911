import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
const claimSubcontractorsService = {
  all(claimId, projectId) {
    return fetch(`/api/claims/${claimId}/claim_project_reports/${projectId}/claim_contractors`, request.get);
  },
  delete(claim_contractor) {
    return fetch(`/api/claims/${claim_contractor.claim_id}/claim_project_reports/${claim_contractor.claim_project_report_id}/claim_contractors/${claim_contractor.id}`, request.delete);
  },
  update(claim_contractor) {
    return fetch(`/api/claims/${claim_contractor.claim_id}/claim_project_reports/${claim_contractor.claim_project_report_id}/claim_contractors/${claim_contractor.id}`, {
      ...request.put,
      body: JSON.stringify({
        claim_contractor
      })
    });
  },
  create(claim_contractor) {
    return fetch(`/api/claims/${claim_contractor.claim_id}/claim_project_reports/${claim_contractor.claim_project_report_id}/claim_contractors`, {
      ...request.post,
      body: JSON.stringify({
        claim_contractor
      })
    });
  }
};
export default claimSubcontractorsService;